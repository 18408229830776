<template>
  <section class="main-section points">
     <div class="filters">
       <label>Filter by</label>
       <ul>
         <li v-if="me.is_admin">
           <user-groups-filter v-model="filters.user_groups" :label="filter_label('groups')" />
         </li>
         <li>
           <user-segment-filter v-model="filters.user_segments" :label="filter_label('segments')" />
         </li>
       </ul>
     </div>

    <div class="content">
      <div class="table-wrap">
        <template v-if="activity !== null">
          <ht-sortable-table v-if="activity.length > 0" :rows="activity" :columns="['Date', 'Type', 'Giver', 'Earner', 'Points']" :sortable="{'1': 'created_at', '2': 'type', '4': 'user_to.name', '5': 'points'}">
            <template #rows="{rows, sort_by}">
              <tr v-for="(row, index) in rows" :key="`activity_row_${sort_by}_${index}`">
                <td>
                  {{$formatDate(row.created_at)}}
                </td>
                <td>
                  {{row.type === 'SHOUTOUT' ? 'Shoutout' : 'Award'}}
                </td>
                <td>
                  <template v-if="row.type === 'AWARD' && row.award_earned.award.is_bot === true">
                    {{bot_name(row.award_earned.award.bot_type)}}
                  </template>
                  <user-card :user="row.type === 'SHOUTOUT' ? row.shoutout.created_by : row.award_earned.created_by" :height="22" />
                </td>
                <td>
                  <user-card :user="row.user_to" :height="22" />
                </td>
                <td>
                  {{$formatPoints(row.points)}}
                </td>
              </tr>
            </template>
          </ht-sortable-table>
          <div class="empty-state" v-else>No activity found with these filters.</div>
        </template>
        <loading-indicator v-else />
      </div>
    </div>
  </section>
</template>

<script>
import {debounce} from 'lodash'

import UserGroupsFilter from '@/components/Reporting/Filters/UserGroups'
import UserSegmentFilter from '@/components/Reporting/Filters/UserSegments'
import ValuesFilter from '@/components/Reporting/Filters/Values'

export default {
  props: ['dateRange'],
  components: {
    UserGroupsFilter,
    UserSegmentFilter,
    ValuesFilter
  },
  computed: {
    me() {
      return this.$store.state.user;
    }
  },
  data() {
    return {
      activity: null,
      filters: {
        user_groups: [],
        user_segments: [],
        date_range: this.dateRange
      }
    }
  },
  watch: {
    dateRange(val) {
      this.filters.date_range = val;
    },
    filters: {
      deep: true,
      handler: function() {
        this.populateActivity()
      }
    }
  },
  beforeDestroy() {
    this.$parent.$off('exportTable', this.export);
  },
  created() {
    if(this.$route.query.group) this.filters.user_groups = [this.$route.query.group.toString()];

    this.populateActivity();

    this.$parent.$on('exportTable', this.export)
  },
  methods: {
    bot_name(bot) {
      switch(bot) {
        case "BIRTHDAY": 
          return `Birthday bot`;
        case "NEW_HIRE":
          return `New hire bot`;
        case "ANNIVERSARY":
          return `Anniversary bot`;
      }
    },
    populateActivity: debounce( async function() {
      this.activity = null;
      const resp = await this.$api.Reporting.get_points_earned(this.filters)
      this.activity = resp;
    }, 250),
    filter_label(filter) {
      switch(filter) {
        case "segments":
          return this.filters.user_segments.length > 0 ? `Segments (${this.filters.user_segments.length})` : 'Segments';
        case "sent_by":
          return this.filters.sent_by_segments.length > 0 ? `Sent by (${this.filters.sent_by_segments.length})` : 'Sent by';
        case "received_by":
          return this.filters.received_by_segments.length > 0 ? `Received by (${this.filters.received_by_segments.length})` : 'Received by';
        case "groups":
          return this.filters.user_groups.length > 0 ? `Channels (${this.filters.user_groups.length})` : 'Channels';
        case "values":
          return this.filters.values.length > 0 ? `Values (${this.filters.values.length})` : 'Values'
      }
    },
    export() {
      const custom_fields = Object.entries(this.activity[0]).reduce((agg, [key, val]) => {
        if(key.substr(0, 3) === 'CF_') agg.push(key.substr(3));
        return agg;
      }, [])

      window.generateCSV('HiThrive Points Earned Report.csv', [
        ['Date', 'Type', 'Giver', 'Earner', 'Points', ...custom_fields],
        ..._.map(this.activity, r => ([
        this.$csvDateFormat(r.created_at),
          r.type === 'SHOUTOUT' ? 'Shoutout' : 'Award',
          r.type === 'AWARD' && r.award_earned.award.is_bot ? r.award_earned.award.bot_type : (r.type === 'SHOUTOUT' ? r.shoutout.created_by.email : r.award_earned.created_by.email),
          r.user_to.email,
          r.points,
          ...Object.entries(r).reduce((agg, [k, v]) => {
            if(k.substr(0, 3) === 'CF_') agg.push(v);
            return agg;
          }, [])
        ]))
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  margin: 25px 0 0;
}
.table-wrap {
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid $light;

  ::v-deep > table {
    font-size: 14px;
    margin: 0;
    
    thead {
      th {
        border-top: none;
      }
    }
  }
}
</style>